<template>
  <div class="StaffStreams">
    <heading>Edit stream as a staff member</heading>
    <form-field label="Pick a brand">
      <basic-remote-search-select
        v-model="query.brand"
        :search-method="findBrands"
        data-testid="BrandPicker"
        popper-class="BrandPickerDropdown"
      />
    </form-field>
    <form-field label="Pick a stream">
      <basic-select
        v-loading="isFetchingStreams"
        :value="query.stream"
        :values="streams"
        label-prop="title"
        value-key="id"
        data-testid="StreamPicker"
        popper-class="StreamPickerDropdown"
        @input="selectStream"
      />
    </form-field>
    <div v-if="query.stream" class="StreamSettings">
      <form @submit.prevent="updateStream">
        <div
          class="column is-12"
          data-testid="ManageStreamsSection--Staff"
        >
          <heading size="4">
            Manage Stream Staff settings
          </heading>
          <div>
            <form-field label="Quota Limit">
              <input
                v-model.number="streamForm.quota_limit"
                type="number"
                class="input"
                data-testid="QuotaLimitInput"
              >
            </form-field>
          </div>
          <v-separator />
          <div class="columns">
            <div class="column">
              <el-checkbox
                v-model="streamForm.auto_clipping"
                data-testid="EnableAutoClipping"
              >
                {{ $t('pages.streams.manage_streams.enable_autoclipping') }}
              </el-checkbox>
            </div>
            <div class="column">
              <form-field :label="$t('pages.streams.manage_streams.auto_tags')">
                <tags-list
                  v-model="streamForm.auto_tags"
                  :disabled="!streamForm.auto_clipping"
                  name="auto_tags"
                  data-testid="AutoTagsInput"
                />
              </form-field>
              <form-field :label="$t('pages.streams.manage_streams.auto_kpis')">
                <basic-select
                  v-model="streamForm.auto_kpi_campaigns"
                  :values="kpi_campaigns"
                  :disabled="!streamForm.auto_clipping"
                  value-prop="id"
                  key-prop="id"
                  label-prop="name"
                  name="auto_tags"
                  multiple
                  data-testid="AutoKPIsSelect"
                  popper-class="AutoKPIsSelectDropdown"
                />
              </form-field>
            </div>
          </div>
        </div>
        <div class="buttons is-right">
          <v-button
            type="submit"
            class="is-primary"
            :loading="isSaving"
            data-testid="SaveTrigger"
          >
            Save
          </v-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BasicRemoteSearchSelect from '@hypefactors/shared/js/components/core/BasicRemoteSearchSelect'
import TagsList from '@/components/core/TagsList'

const streamFormFactory = () => ({
  id: '',
  quota_limit: '',
  auto_clipping: '',
  auto_tags: '',
  auto_kpi_campaigns: ''
})

/**
 * @module StaffStreams
 */
export default {
  name: 'StaffStreams',
  components: {
    BasicRemoteSearchSelect,
    TagsList
  },
  data () {
    return {
      streamForm: streamFormFactory(),
      query: {
        brand: null,
        stream: null
      },
      streams: [],
      kpi_campaigns: [],
      isFetchingStreams: false,
      isSaving: false
    }
  },
  watch: {
    'query.brand': function (value) {
      if (!value) return
      this.streams = []
      this.kpi_campaigns = []
      this.fetchKpis()
      this.fetchStream()
    }
  },
  methods: {
    findBrands (params) {
      return this.$api.getData('/staff/brands', params)
    },
    selectStream (stream) {
      this.query.stream = stream
      this.streamFormMerge(stream)
    },
    async fetchStream () {
      this.query.stream = null
      // this.query.brand.id should be used
      this.isFetchingStreams = true
      this.streams = await this.$api.getData(`/staff/brands/${this.query.brand.id}/streams`, {
        params: {
          include: ['auto_kpi_campaigns']
        }
      })
      this.isFetchingStreams = false
    },
    streamFormMerge (stream) {
      this.streamForm = {
        id: stream.id,
        quota_limit: stream.quota_limit,
        auto_clipping: stream.auto_clipping,
        auto_tags: stream.auto_tags,
        auto_kpi_campaigns: stream.auto_kpi_campaigns.data.map(kpi => kpi.id)
      }
    },
    async updateStream () {
      this.isSaving = true
      await this.$api.put(`/staff/brands/${this.query.brand.id}/streams/${this.query.stream.id}`, this.streamForm)
      this.isSaving = false
      this.reset()
    },

    async fetchKpis () {
      const params = {
        params: {
          brands: [this.query.brand.id]
        }
      }
      try {
        this.kpi_campaigns = await this.$api.getData('kpi-campaigns', params)
      } catch (e) { this.$displayRequestError(e) }
    },
    reset () {
      this.streamForm = streamFormFactory()
      this.query.brand = null
      this.query.stream = null
    }
  }
}
</script>

<template>
  <el-select
    v-model="computedValue"
    v-loading="isLoading"
    v-bind="$attrs"
    :remote="shouldSearch"
    :remote-method="__search"
    value-key="id"
    filterable
    default-first-option
  >
    <el-option
      v-for="organisation in itemsToLoopOver"
      :key="organisation.id"
      :label="organisation[valueLabel]"
      :value="organisation"
    />
  </el-select>
</template>

<script>
import { remoteSelectMixin } from '../../mixins/remoteSelectMixin'

/**
 * @module BasicRemoteSearchSelect
 */
export default {
  name: 'BasicRemoteSearchSelect',
  extends: remoteSelectMixin,
  props: {
    searchMethod: {
      type: Function,
      required: true
    },
    valueLabel: {
      type: String,
      default: 'name'
    }
  },
  methods: {
    search (...params) {
      return this.searchMethod(...params)
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

</style>
